import { CurrentCountryContext } from "@components/all-pages/CurrentCountryContext";
import { ErrorBoundary } from "@components/ErrorBoundary";
import { GlowError } from "@components/GlowError";
import { FALLBACK_COUNTRY_CODE, GLOW_HOST } from "@luma-team/shared";
import { ComponentsOverridesContext } from "@lux/atoms/components/ComponentsOverridesContext";
import { useIsMounted } from "@lux/atoms/hooks/useIsMounted";
import "@lux/sass/styles/app.scss";
import App, { AppContext } from "next/app";
import Head from "next/head";
import Link from "next/link";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Toaster } from "react-hot-toast";
import "../styles/overrides.scss";
import { useDarkMode } from "@lux/atoms/hooks/useDarkMode";
import { useRouter } from "next/router";
import { FramerProvider } from "@lux/atoms/components/FramerProvider";

const COMPONENTS_OVERRIDES: React.ContextType<
  typeof ComponentsOverridesContext
> = {
  Link: React.forwardRef(
    ({ href, ...props }, ref: React.ForwardedRef<HTMLAnchorElement>) => (
      <Link href={href} legacyBehavior={true}>
        <a {...props} ref={ref} />
      </Link>
    )
  ),
  HOST: GLOW_HOST,
};

export default function GlowApp(appProps: {
  Component: React.Factory<any>;
  pageProps: any;
  err: Error;
  countryCode: string;
}) {
  const { Component, pageProps, err } = appProps;
  const isMounted = useIsMounted();

  // Workaround for https://github.com/zeit/next.js/issues/8592
  const modifiedPageProps = { ...pageProps, err };

  const { isDarkMode } = useDarkMode();
  const router = useRouter();
  useEffect(() => {
    if (router.asPath === "/") {
      (window as any).__setPreferredTheme("dark");
    } else {
      (window as any).__setPreferredTheme(isDarkMode ? "dark" : "light");
    }
  }, [isDarkMode, router.asPath]);

  return (
    <ErrorBoundary>
      <ComponentsOverridesContext.Provider value={COMPONENTS_OVERRIDES}>
        <FramerProvider>
          <CurrentCountryContext.Provider
            value={{ country_code: appProps.countryCode }}
          >
            {pageProps?.errorCode ? (
              <GlowError statusCode={pageProps.errorCode} />
            ) : (
              <>
                <Head>
                  <title>Glow Wallet — Your New Favorite Solana Wallet</title>
                  <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                </Head>

                {isMounted &&
                  ReactDOM.createPortal(
                    <Toaster position="bottom-center" />,
                    document.body
                  )}

                <Component {...modifiedPageProps} />
              </>
            )}
          </CurrentCountryContext.Provider>
        </FramerProvider>
      </ComponentsOverridesContext.Provider>
    </ErrorBoundary>
  );
}

GlowApp.getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext;
  let countryCode = FALLBACK_COUNTRY_CODE;

  if (ctx.req) {
    const cloudflareIpCountry =
      // Cloudflare provides country in production
      (ctx.req.headers["cf-ipcountry"] as string) ||
      // Vercel provides country in staging (luma-dev.com)
      (ctx.req.headers["x-vercel-ip-country"] as string);

    // Cloudflare uses:
    // - XX for clients without country code data
    // - T1 for clients using the Tor network
    const invalidCountryCodes = ["XX", "T1"];
    if (
      cloudflareIpCountry &&
      !invalidCountryCodes.includes(cloudflareIpCountry)
    ) {
      countryCode = cloudflareIpCountry;
    }
  }

  const extraProps = await App.getInitialProps(appContext);

  return { countryCode, ...extraProps };
};
