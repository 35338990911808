import { FALLBACK_COUNTRY_CODE } from "@luma-team/shared";
import React from "react";

import { createUseAppContext } from "@utils/context";

export const CurrentCountryContext = React.createContext<{
  country_code: string;
}>({
  country_code: FALLBACK_COUNTRY_CODE,
});

export const useCurrentCountry = createUseAppContext(
  CurrentCountryContext,
);
