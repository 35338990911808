/**
 * This uses CloudFlare's image resizing API to get a URL of a specific size from CloudFlare
 *
 * This only works on the cdn.lu.ma domain. All other URLs will be unmodified.
 */
import { croppedUnsplashImage, isUnsplashImage } from "./unsplash";

export namespace Cdn {
  const cdnUrls = ["https://cdn.lu.ma/", "https://images.lumacdn.com/"];

  export function isCdnUrl(url: string, options?: { path?: string }): boolean {
    let path = options?.path ?? "";

    // Trim "/" from beginning of path
    if (path.startsWith("/")) {
      path = path.slice(1);
    }

    return cdnUrls.some((cdnUrl) => url.startsWith(cdnUrl + path));
  }
}

const CLOUDFLARE_SUPPORTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
];

export const CDN_SUPPORTED_IMAGE_TYPES = CLOUDFLARE_SUPPORTED_IMAGE_TYPES;

export const getImageUrl = ({
  url,
  width,
  height,
  mime_type,
  quality = 75,
  dpr = 2,
  blur,
  fit = "cover",
  noAnimation,
  format = "auto",
}: {
  url: string | null;
  width: number;
  height?: number;
  mime_type?: string | null;
  quality?: number;
  blur?: number;
  dpr?: number;
  fit?: "crop" | "cover" | "scale-down" | null;
  noAnimation?: boolean;
  /**
   * - "auto" = default, choose best format based on browser support
   * - "legacy" = JPG or PNG
   */
  format?: "auto" | "legacy";
}): string | undefined => {
  if (!url) {
    return;
  }

  // We have already done cropping, so respect the cropping that is already applied
  if (
    url.startsWith("https://cdn.lu.ma/cdn-cgi/image/") ||
    url.startsWith("https://images.lumacdn.com/cdn-cgi/image/") ||
    url.startsWith("https://social-images.lu.ma/cdn-cgi/image/") ||
    url.startsWith("https://cdn.glow.app/cdn-cgi/image/") ||
    url.startsWith("https://images.glow.app/") ||
    url.startsWith("http://localhost")
  ) {
    return url;
  }

  // If it's a Data URL we won't do any processing
  if (url.startsWith("data:")) {
    return url;
  }

  // Don't use Cloudflare resizing for gifs to avoid animated gifs falling foul
  // of max frame/size limits.
  if (mime_type === "image/gif" || url.endsWith(".gif")) {
    return url;
  }

  if (isUnsplashImage(url)) {
    return croppedUnsplashImage({ src: url, width, height, dpr });
  }

  try {
    const parsedUrl = new URL(url);

    let cdnUrl = parsedUrl.origin;
    cdnUrl += "/cdn-cgi/image/";

    // Cloudflare Image URL Options - https://developers.cloudflare.com/images/url-format
    const options = format === "auto" ? ["format=auto"] : [];
    if (fit) {
      options.push(`fit=${fit}`);
    }
    if (dpr) {
      options.push(`dpr=${dpr}`);
    }
    if (blur) {
      options.push(`blur=${blur}`);
    }
    if (noAnimation) {
      options.push(`anim=false`);
    }
    if (quality) {
      options.push(`quality=${quality}`);
    }
    if (width) {
      options.push(`width=${width}`);
    }
    if (height) {
      options.push(`height=${height}`);
    }
    cdnUrl += options.join(",");

    return cdnUrl + parsedUrl.pathname + parsedUrl.search;
  } catch (e) {
    console.error("Problem with image", e);
    return;
  }
};
